@import "../colors";
@import "../variables";

.container-full {
  margin: 0 auto;
  width: 100%;
}

nav.navbar {
  min-height: @navbar-height;
  margin-bottom: @navbar-margin-bottom;
  box-shadow: inset 0px 5px 6px -3px rgba(0, 0, 0, 0.4);
}

nav.navbar-inverse {
  background-color: @navbar_grey;
}

a.navbar-brand {
  padding: 15px 10px;
}

#brand {
  color: @white;
  margin-right: 15px;
}

nav.navbar-static-top {
  border: 0;
  position: fixed;
  width: 100%;
  padding: 0 10px;
  top: 0;
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.4);
}

nav.navbar ul.navbar-nav {
  //height: @navbar-height;
  li {
    font-size: larger;
    //text-transform: uppercase;
    //font-size: 80%;
    //font-weight: 900;
    a {
      color: @white;
    }
  }
}

ul.navbar-nav {
  margin-left: -15px;
}

ul.navbar-nav > li {
  border-bottom: 3px solid @navbar_grey;
}

ul.navbar-nav > li:hover {
  font-weight: 700;
  border-bottom: 3px solid white;
}

.nav > li > a {
    padding: 15px 10px;
}

// Banner
#banner-image {
  background-image: url('../../../img/blue-crystal2.jpg');
  img {max-height: 300px;
      align-self:center;
  }
}
#mid-banner, .banner-div {
  align-self:center;
  align-items:center;
}



.banner {
  margin-top: -@navbar-margin-bottom;
  margin-bottom: @navbar-margin-bottom;
  color: @white;

  h1 {
    font-size: 50px;
    line-height: 90%;
    font-weight: bold;
  }
  h3 {
    font-size: 17px;
    margin: 0;
  }
  h4 {
    font-size: 13px;
    margin: 0;
  }
}

.banner-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 25px 0;
}

.banner-breadcrumb {
  height: 100px;

}

.banner-shadow {
  height: 6px;
}

.tab-content {
  padding: 10px;
}

.odd {
  background-color: white;
}

.gradient-shadow {
  background-image: -ms-radial-gradient(center top, ellipse farthest-corner, #CCC 0%, transparent 70%);
  background-image: -moz-radial-gradient(center top, ellipse farthest-corner, #CCC 0%, transparent 70%);
  background-image: -o-radial-gradient(center top, ellipse farthest-corner, #CCC 0%, transparent 70%);
  background-image: -webkit-gradient(radial, center top, 0, center top, 561, color-stop(0, #CCC), color-stop(0.7, transparent));
  background-image: -webkit-radial-gradient(center top, ellipse farthest-corner, #CCC 0%, rgba(0,0,0,0) 70%);
  background-image: radial-gradient(ellipse farthest-corner at center top, #CCC 0%, transparent 70%);
  height:7px;
  width:80%;
  margin:0 auto;
}

ul.navbar-nav {
  margin: 0 5px;
}

.float-right {
  float: right;
}