@import "colors";
@import "variables";

@import "components/navbar";
@import "components/footer";
@import "pages/collaborators";
@import "pages/member_list";

* {
  margin: 0;
}

html {
  height: 100%;
}

body {
  height: 100%;
  overflow-y: scroll;
  font-family: @font-family-sans-serif;
}

.grayscale {
  filter: grayscale(100%);
}

.banner-content {
  text-shadow: 2px 2px #1D2D3C;
}

.banner-content > h2 {
  margin-top: 10px;
}

section {
  font-size: 120%;
  line-height: 150%;

  .section-header {
    margin: 0;
    text-transform: uppercase;

  }
  .section-header-underline{
    @extend .section-header;
    text-decoration: underline;
  }

  .section-header-bold{
    @extend .section-header;
    font-weight: bold;
  }

  .section-subtitle {
    padding-top: 25px;
    width: 70%;
  }
}

.service_box {
  //background-color: @lightblue;
  //background-image: url('../../img/crystals.jpg');
  justify-content: center;
}
.service_flex {
  display: flex;
  align-items: stretch;
  //max-width: 90%;
  flex-wrap: wrap;
  margin-top: 20px;
}
.service {
    padding: 20px 50px;
    //border-radius: 25px;
    //border: 4px solid #1969dc;
    min-width: 350px;
    max-width: 50%;
    flex: 1 1 50%;
    background-color: white;
    font-size: 120%;
}
.centered_div {
  display: flex;
  justify-content: space-around;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}


.involvement {
    overflow: hidden;
    padding: 30px 0;
}

.right {
    text-align: justify;
    float: right;
    width: 85%;
    height: 100%;
}

.left {
    color: @blue;
    overflow: hidden;
    height: 100%;
}
// Alumni styles
.trainees-grid {
  font-size: 0.8em;
  padding: 0;
}

.trainees-header {
  font-size: 0.8em;
  padding: 0;
  margin: 10px 0 0 0;
  font-weight: bold;
}

.trainee-style {
  width: 100%;
  clear: both;
  border-bottom: 1px solid;
  list-style: none;
  margin: 0;
}

.trainee-present {
  background-color: #dcdfe2;
}

.trainee.IF {
    background-color:#b2e8b2;
}
.trainee.IF.trainee-present {
    background: repeating-linear-gradient(
      45deg,
      #b2e8b2 ,
      #b2e8b2 10px,
      #dcdfe2 10px,
      #dcdfe2 20px
);
}

.current_position.Ein {background-color:#aafbff }
.current_position.Eac {background-color:#d8aaff }
.current_position.Ehc {background-color:#aad1ff }

.trainee-style > * {
  display: inline-block;
  padding: 4px 4px;
}

.trainee-style .trainee-name { width: 10%; }
.trainee-style .status { width: 5%; }
.trainee-style .training_period { width: 10%; }
.trainee-style .prior_institution { width: 10%; }
.trainee-style .prior_degree { width: 5%; }
.trainee-style .prior_degree_year { width: 5%; }
.trainee-style .project_title { width: 20%; }
.trainee-style .current_position { width: 15%; }
.trainee-style .pub_num { width: 7%; }
.trainee-style .citation_total { width: 8%; }
.odd {background-color: transparent}
.even {background-color: 'white'}

.hangingindent {
  padding-left: 22px ;
  text-indent: -22px ;
}

.img-thumbnail {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  margin: 0 0 5px;
}

.img-rounded {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 200px;
}

.structure-list > li {
  padding-bottom: 15px;
}

.float-right {
  float: right;
}

.footer-contact {
  color: white;
}

.list-inline > li {
  vertical-align: top;
  padding-left: auto;
  padding-right: auto;
}

.wrapper > div {
    width: 150px;
    margin: 10px;
    display: inline-block;
    vertical-align: top;
}

@media screen and (max-width: 768px) {
  .wrapper > div {
    margin: auto;
  }

}

.wrapper:after {
    content: "";
    width: 100%;
    display: inline-block;
}

.img-rounded {
  border: 1px solid white;
}

.img-thumbnail:hover {
  border: 1px solid wheat;
  box-shadow: 0 5px 10px wheat;
  color: wheat;
}

.img-thumbnail > img:hover {
  filter: grayscale(0%);
}

.pdb-thumbnail-label {
  text-align: center;
  font-size: 21px;
  margin-top: -5px;
}

.no-underline:hover {
    text-decoration: none;
}

.anchor-padding {
  display: block;
  height: 50px; /*same height as header*/
  margin-top: -50px; /*same height as header*/
  visibility: hidden;
}

#lab-map {
  border:0;
  padding: 5px;
  margin: 10px 0 15px;
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}

.img-thumbnail > img {
  filter: grayscale(60%);
}

.mainpage-interest-boxes {
  font-size: 20px;
  color: #337ab7;
}
.small_box {
    font-size: 14px;
    color: #337ab7;
    margin: 10px;
  	border: 3px solid #888;
  	padding: 15px;
  	border-radius: 10px;
    box-shadow: 5px 10px 5px rgba(0, 0, 0, 0.4);
    background-color: #fff;
    display: flex;
    text-align: center;
}


gresources-box {
  border-top: 1px solid #ddd;
}

.mainpage-cover {
  margin: 0px 5px;
}

figure {
  display: table;
}

figcaption {
  display: table-caption;
  caption-side: bottom;
  padding: 10px;
}

.pull-right {
  padding: 0 10px 10px;
}


.picture_box {
  margin: 0px;
  border: 3px solid #888;
  padding: 15px;
  border-radius: 25px;
  background-color: #eee;
}
.big_box {
  margin: 20px auto;
  border: 3px solid #888;
  padding: 15px;
  border-radius: 25px;
  box-shadow: 5px 10px 5px rgba(0, 0, 0, 0.4);
  background-color: #eee;
}
.big_box img {
  margin:auto;
}
.iframe-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
}

.iframe-container iframe {
   border: 0;
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
}
.publication {
  padding-bottom: 15px;
}
.member-card-row {
  display: inline-flex;
}

// BEGIN overlay

.overlay {
  height: 50%;
  width: 200px;
  position: fixed;
  z-index: 1;
  top: 20%;
  left: 0%;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0, 0.9);
  overflow-x: hidden;
  transition: 0.5s;
  color: #f1f1f1;
}

.overlay-content {
  position: relative;
  top: 20%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
  text-decoration: none;
  font-size: 16px;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

@media screen and (max-height: 450px) {
  .overlay a {font-size: 20px}
  .overlay .closebtn {
  font-size: 40px;
  top: 15px;
  right: 35px;
  }
}
