ul.collaborators {
  margin-bottom: 0px;
  li {
    padding-bottom: 8px;
  }

  li:last-child {
    padding-bottom: 0px;
  }
}

.map-container {
  display:inline-block;
  overflow:hidden;
  border-radius: 4px;
  margin: 10px 0px 50px;
}

.collaborators-map {
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;
  top:-50px;
  border:none;
}
