.table > tbody > tr > td {
  border-top: none;
  border-bottom: 1px solid #ddd;
}

.member-card {
  display: inline-flex;
  min-width: 450px;
  max-width: 450px;
  padding: 5px;
  margin: 10px auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}
.award-card {
  padding: 5px;
  margin: 10px auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}

.member-card-text {
  margin: 5px 15px;
}

.member-card-photo > a > img {
  min-width: 150px;
  min-height: 160px;
  box-shadow: none;
  border-color: white;
  border-radius: 0px;
}

.member-card-photo > a > .img-thumbnail {
  margin: 0;
}
