@import "../colors";
@import "../variables";

#wrap {
  min-height: 100%;
  height: auto !important;
  height: 100%;
}

@media (max-width: 768px) {
  #wrap{
    /* Negative indent footer by it's height */
    margin: 60px auto -@footer-height;
  }
}

@media (min-width: 769px) and (max-width: 884px) {
  #wrap{
    /* Negative indent footer by it's height */
    margin: 123px auto -@footer-height;
  }
}

@media (min-width: 885px) {
  #wrap{
    /* Negative indent footer by it's height */
    margin: 73px auto -@footer-height;
  }
}

/* Set the fixed height of the footer here */
#push{
  margin-top: 20px;
  height: @footer-height;
}

/* Lastly, apply responsive CSS fixes as necessary */
@media (max-width: 767px) {
  #footer {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

footer {
  width: 100%;
  display: table;
  justify-content: center;
  flex-direction: column;
  min-height: @footer-height;
  background-color: @navbar_grey;
  box-shadow: inset 0px 5px 6px -3px rgba(0, 0, 0, 0.4);

  .container {
    height: 100%;
  }

  h3 {
    color: @white;
  }

  .list-inline > li {
    padding-left: 40px;
    padding-right: 40px;
  }

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid rgb(51, 51, 51);
    margin-bottom: 5px;
    margin-top: 20px;
    padding: 0;
    color: @white;
  }

  a, a:hover, p {
    color: @white;
  }
}

.footer-logo {
  margin: 20px auto;
  min-width: 270px;
}

#uva-logo {
  margin: 0 15px 5px 30px;
}

ul {
  margin-bottom: 20px;
}